<template>
  <router-view></router-view>
</template>

<script>
import { provide } from "vue";
import globalStore from "@/globalStore";
import { msalInstance } from "@/plugins/msal";

export default {
  setup() {
    provide("globalStore", globalStore);
    // Largely inspired by:
    // - https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/initialization.md
    // - https://devblogs.microsoft.com/azure-sdk/vue-js-user-authentication/
    msalInstance
      .handleRedirectPromise()
      .then(handleRedirectResponse)
      .catch((err) => console.log(err));

    function handleRedirectResponse(tokenResponse) {
      //   Check if the tokenResponse is null
      // If the tokenResponse !== null, then you are coming back from a successful authentication redirect.
      if (tokenResponse !== null) {
        let account = tokenResponse.account;
        msalInstance.setActiveAccount(account);
        globalStore.authenticate(account);
      }
      // If the tokenResponse === null, you are not coming back from an auth redirect.
      else {
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length == 0) {
          try {
            globalStore.deauthenticate();
            msalInstance.loginRedirect({});
          } catch (err) {
            console.log(err);
          }
        } else {
          msalInstance.setActiveAccount(accounts[0]);
          globalStore.authenticate(accounts[0]);
        }
      }
    }
    return {};
  },
};
</script>