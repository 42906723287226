import Vue from "vue";
import VueRouter from "vue-router";
import App from "../App";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: App,
    children: [
      {
        path: "/",
        redirect: "/schedule",
      },
      {
        path: "/admin",
        name: "AdminView",
        component: () =>
          import(/* webpackChunkName: "admin" */ "@/views/AdminView.vue"),
      },
      {
        path: "/finance",
        name: "FinanceView",
        component: () =>
          import(/* webpackChunkName: "finance" */ "@/views/FinanceView.vue"),
      },
      {
        path: "/houranalysis",
        name: "HourAnalysis",
        component: () =>
          import(
            /* webpackChunkName: "houranalysis" */ "@/views/HourAnalysis.vue"
          ),
      },
      {
        path: "/project/:ref",
        name: "ProjectView",
        component: () =>
          import(/* webpackChunkName: "project" */ "@/views/ProjectView.vue"),
      },
      {
        path: "/schedule",
        name: "ScheduleView",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "schedule" */ "@/views/ScheduleView.vue"),
      },

      {
        path: "/time",
        name: "TimeView",
        component: () =>
          import(/* webpackChunkName: "time" */ "@/views/TimeView.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

export default router;
