import axios from "axios";
import AuthService from "@/services/AuthService";

const API_URL = process.env.VUE_APP_ROOT_API;

export default {
  async get(path, options = {}) {
    const token = await AuthService.getToken();

    const response = await axios.get(API_URL + path, {
      params: options,
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data;
  },

  async post(path, options = {}) {
    const token = await AuthService.getToken();
    const response = await axios.post(API_URL + path, options, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  },

  async delete(path) {
    const token = await AuthService.getToken();
    const response = await axios.delete(API_URL + path, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  },

  async put(path, options = {}) {
    const token = await AuthService.getToken();
    const response = await axios.put(API_URL + path, options, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  },

  async patch(path, options = {}) {
    const token = await AuthService.getToken();
    const response = await axios.patch(API_URL + path, options, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  },
};
