import { reactive, readonly } from "vue";


// Largely inspired by https://vuejsdevelopers.com/2020/10/05/composition-api-vuex/

const state = reactive({
  user: {},
  authenticated: false,
});

const authenticate = function(user) {
  state.user = user;
  state.authenticated = true;
};

const deauthenticate = function() {
  state.user = {};
  state.authenticated = false;
};

export default { state: readonly(state), authenticate, deauthenticate };
