<template>
  <v-autocomplete
    v-model="state.search.model"
    :items="state.search.results"
    :search-input.sync="state.search.text"
    :loading="state.search.loading"
    flat
    solo-inverted
    hide-details
    hide-no-data
    no-filter
    label="Search"
    placeholder="Search ..."
    prepend-inner-icon="mdi-magnify"
    class="hidden-sm-and-down"
  >
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title> Search projects </v-list-item-title>
      </v-list-item>
    </template>
    <template v-slot:item="{ item }">
      <v-list-item link :to="'/project/'.concat(item.reference)">
        <v-chip small label>
          {{ item.reference }}
        </v-chip>
        <v-list-item-content>
          {{ item.client.name }} -
          {{ item.name }}
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
import { reactive, watchEffect } from "vue";
import ApiService from "@/services/ApiService";

export default {
  name: "SearchBar",
  components: {},
  setup() {
    const state = reactive({
      search: {
        text: "",
        results: [],
        input: "",
        loading: false,
        waiting: false,
        model: null,
      },
    });

    watchEffect(() => {
      if (state.search.text && state.search.text !== "") {
        search();
      }
    });

    function search() {
      if (state.search.text && state.search.text !== "") {
        if (state.search.timer) {
          clearTimeout(state.search.timer);
          state.search.results = [];
          state.search.timer = null;
        }
        state.search.loading = true;
        state.search.timer = setTimeout(() => {
          ApiService.post(`search`, { text: state.search.text })
            .then((res) => {
              state.search.results = res.projects;
            })
            .catch(() => {
              // MessageService.error("Saving project failed");
            })
            .finally(() => (state.search.loading = false));
        }, 1000);
      } else {
        state.search.results = [];
      }
    }

    return {
      state,
    };
  },
};
</script>
