import { Role } from "@/auth/role";
import { msalInstance } from "../plugins/msal";

export default {
  isAuthenticated() {
    return msalInstance.getActiveAccount() !== null;
  },
  async getToken() {
    let token = await msalInstance.getTokenSilent();
    return token.idToken;
  },
  hasRole(role) {
    return this.isAuthenticated()
      ? msalInstance.getActiveAccount().idTokenClaims.roles.includes(role)
      : false;
  },
  isAdmin() {
    return this.hasRole(Role.Admin);
  },
  isFounder() {
    return this.hasRole(Role.Founder);
  },
  isFalse() {
    //Only for testing purposes :)
    return false;
  },
};
