<template>
  <v-app id="inspire" v-if="globalStore.state.authenticated">
    <v-navigation-drawer
      v-model="state.drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      :mini-variant="true"
      :expand-on-hover="true"
      app
    >
      <v-list dense>
        <template v-for="item in state.items">
          <v-row v-if="item.heading" :key="item.heading" align="center">
            <v-col cols="6">
              <v-subheader v-if="item.heading">{{ item.heading }}</v-subheader>
            </v-col>
            <v-col cols="6" class="text-center">
              <a href="#!" class="body-2 black--text">EDIT</a>
            </v-col>
          </v-row>
          <v-list-group
            v-else-if="item.children"
            :key="item.text"
            v-model="item.model"
            :prepend-icon="item.model ? item.icon : item['icon-alt']"
            append-icon
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="(child, i) in item.children"
              :key="i"
              link
              :to="child.link"
            >
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ child.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else :key="item.text" link :to="item.link">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="purple darken-3"
      dark
    >
      <v-app-bar-nav-icon
        @click.stop="state.drawer = !state.drawer"
      ></v-app-bar-nav-icon>

      <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
        <span class="hidden-sm-and-down"
          ><v-btn icon large>
            <v-avatar size="32px" item>
              <v-img src="@/assets/logo.png" alt="FalconForce"></v-img>
            </v-avatar>
          </v-btn>
          Backoffice</span
        >
      </v-toolbar-title>
      <SearchBar />
      <v-spacer></v-spacer>
      <!-- <v-btn icon>
        <v-icon>mdi-apps</v-icon>
      </v-btn>-->
      <v-badge
        color="red"
        offset-x="20"
        offset-y="20"
        :content="state.notifications"
        v-if="state.notifications > 0"
      >
        <v-btn icon to="/time">
          <v-icon>mdi-bell</v-icon>
        </v-btn>
      </v-badge>
      <v-btn fab small color="deep-purple">
        {{ state.name }}
        <!-- <v-avatar size="32px" item>
          <v-img src="@/assets/logo.png" alt="FalconForce"></v-img>
        </v-avatar> -->
      </v-btn>
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { reactive, inject } from "vue";
import ApiService from "@/services/ApiService";
import AuthService from "@/services/AuthService";
import SearchBar from "@/components/common/SearchBar";

export default {
  props: {
    source: String,
  },
  components: { SearchBar },

  setup() {
    const globalStore = inject("globalStore");
    const state = reactive({
      drawer: null,
      notifications: 0,
      items: [
        { icon: "mdi-home", text: "Home", link: "/" },
        { icon: "mdi-compass", text: "Schedule", link: "/schedule" },
        { icon: "mdi-timer", text: "Time writing", link: "/time" },
      ],
      name: "N/A",
    });

    state.name = globalStore.state.user.name
      .split(" ")
      .reduce((acc, word) => acc + word.charAt(0), "");

    if (AuthService.isFounder()) {
      state.items.push({
        icon: "mdi-chevron-up",
        "icon-alt": "mdi-chevron-down",
        text: "Founder area",
        model: false,
        children: [
          // {
          //   icon: "mdi-finance",
          //   text: "Finance",
          //   link: "/finance",
          // },
          {
            icon: "mdi-clock",
            text: "Hour analysis",
            link: "/houranalysis",
          },
        ],
      });
    }
    if (AuthService.isAdmin()) {
      state.items.push({ icon: "mdi-cog", text: "Admin", link: "/admin" });
    }

    ApiService.get("notifications").then((res) => {
      state.notifications = res.notifications;
    });
    document.title = "0xFF";

    return { state, globalStore: globalStore };
  },
};
</script>
